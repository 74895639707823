import React from "react";
import PortfolioPage from "../../components/pages/PortfolioPage";

function Portfolio(props) {
  let entry;
  if (props && props.location && props.location.hash)
    entry = props.location.hash;

  return <PortfolioPage entry={entry} locale="en" />;
}

export default Portfolio;
